import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Disc, X, Circle, Card } from "react-feather";
import classnames from "classnames";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { changeMode } from "../../../../redux/actions/customizer/index";
import logo from "../../../../assets/img/logo/bonyan-logo-only.png";
class SidebarHeader extends Component {
  render() {
    let { toggleSidebarMenu, activeTheme, collapsed, toggle, sidebarVisibility } = this.props;
    return (
      <div className="navbar-header" style={{ marginBottom: "20px" }}>
        <ul className="nav navbar-nav flex-row flex-nowrap align-items-center">
          <li className="nav-item mr-auto">
            <NavLink to="/home" className="navbar-brand">
              <img
                src={logo}
                alt="brand-logo"
                style={{ maxWidth: "100%" }}
              />
              {/* <div className="brand-logo" />
              <h2
                className={`${
                  activeTheme === "primary"
                    ? "primaryclib"
                    : activeTheme === "secondary"
                    ? "secondaryyclib"
                    : activeTheme
                } brand-text mb-0 font-kafaa-131`}
              >
                <FormattedMessage id={"Kafaa"} />
              </h2> */}
            </NavLink>
          </li>
          <li className="nav-item nav-toggle">
            <div className="nav-link modern-nav-toggle">
              {collapsed === false ? (
                <Disc
                  onClick={() => {
                    toggleSidebarMenu(true);
                    toggle();
                  }}
                  className={`${activeTheme === "primary" ? "primaryclib" : activeTheme}`}
                  size={20}
                  data-tour="toggle-icon"
                />
              ) : (
                <Circle
                  onClick={() => {
                    toggleSidebarMenu(false);
                    toggle();
                  }}
                  className={classnames("toggle-icon icon-x d-none d-xl-block font-medium-4", {
                    primaryclib: activeTheme === "primary",
                    "text-success": activeTheme === "success",
                    "text-danger": activeTheme === "danger",
                    "text-info": activeTheme === "info",
                    "text-secondary": activeTheme === "secondary",
                    "text-warning": activeTheme === "warning",
                    "text-dark": activeTheme === "dark",
                  })}
                  size={20}
                />
              )}
              <X
                onClick={sidebarVisibility}
                className={classnames("toggle-icon icon-x d-block d-xl-none font-medium-4", {
                  primaryclib: activeTheme === "primary",
                  "text-success": activeTheme === "success",
                  "text-danger": activeTheme === "danger",
                  "text-info": activeTheme === "info",
                  "text-secondary": activeTheme === "secondary",
                  "text-warning": activeTheme === "warning",
                  "text-dark": activeTheme === "dark",
                })}
                size={20}
              />
            </div>
          </li>
        </ul>
        {/* <div
          className={classnames('shadow-bottom', {
            'd-none': menuShadow === false
          })}
        /> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    app: state.customizer,
  };
};

export default connect(mapStateToProps, {
  changeMode,
})(injectIntl(SidebarHeader));
